/* eslint-disable jsx-a11y/anchor-has-content */
import {twMerge} from '@/stylesheets/twMerge';
import Typography from '@/components/base/elements/Typography/Typography';
import Image from '@/components/shared/Image/Image';
import Link from '@/components/pages/blog/Link/Link';

interface ITopicCardProps {
  className?: string;
  image?: string;
  imageAlt?: string;
  title: string;
  description: string;
  linkText: string;
  linkHref: string;
}

export const TopicCard = ({
  className,
  image,
  imageAlt,
  title,
  description,
  linkText,
  linkHref,
}: ITopicCardProps) => (
  <div className={twMerge('pt-4 border-t border-shade-20', className)}>
    {image && (
      <div className="overflow-hidden mb-4">
        <Image
          className="w-full hover:scale-105 transition-transform duration-300"
          src={image}
          alt={imageAlt}
        />
      </div>
    )}
    <Typography
      as="h3"
      className="text-body-base tablet:text-[20px] font-medium font-aktivgroteskextended"
    >
      {title}
    </Typography>
    <Typography className="text-t7 tablet:text-body-base font-normal my-2 topic-description">
      {description}
    </Typography>
    <Link
      className="text-body-base topic-link"
      link={{
        text: linkText,
        url: linkHref,
      }}
    />
  </div>
);
